import {
  Box,
  DialogTitle as MuiDialogTitle,
  IconButton,
  makeStyles
} from "@material-ui/core";
import * as React from "react";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    backgroundColor: theme.alternativeBackgroundColor
  },
  rootWithCloseButton: {
    paddingRight: 62,
    position: "relative"
  },
  closeButton: {
    right: theme.spacing(1),
    color: theme.palette.grey[700]
  }
}));

type ModalDialogTitleProps = {
  onClose?: () => void;
};

export const ModalDialogTitle: React.FC<ModalDialogTitleProps> = ({
  children,
  onClose,
  ...other
}) => {
  const classes = useStyles();

  return (
    <MuiDialogTitle
      disableTypography
      className={clsx(classes.root, onClose && classes.rootWithCloseButton)}
      {...other}
    >
      {children}
      {onClose ? (
        <Box
          position="absolute"
          top={0}
          bottom={0}
          right={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            size={"small"}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      ) : null}
    </MuiDialogTitle>
  );
};
