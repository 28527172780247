import { PeriodSearchFilterProvider } from "./components/search/PeriodSearchFilterProvider";
import { TextTile } from "./components/tiles/TextTile";
import { registerReactComponentWithAngularModule } from "./register-react-component-with-angular-module";
import { DocumentPageContainer } from "./components/document/document-page";
import { AssetPdfPage } from "./components/assets/AssetPdfPage";
import { TaxonomyTilePageContainer } from "./components/taxonomy-tiles/taxonomy-tile-page";
import { LoginErrorPage } from "./components/error/LoginErrorPage";
import MyProfilePage from "./components/account/my-profile-page/MyProfilePage";
import EditProfilePage from "./components/account/my-profile-page/EditProfilePage";
import { ProfileEmailRequiredContainer } from "./components/account/profile-email-required/ProfileEmailRequiredContainer";
import { ShowMoreToolTip } from "./components/ui-components/ShowMoreToolTip/ShowMoreToolTip";
import AiAssistantPage from "./components/ai-assistant/AiAssistantPage";
import SearchPreview from "./components/search/SearchPreview/SearchPreview";
import { SlaMeasurementMounter } from "./components/sla-measurement/SlaMeasurementMounter";

export default function registerReactComponents(
  angularModuleName: string
): void {
  // <rh-react-document-page />
  registerReactComponentWithAngularModule(
    angularModuleName,
    DocumentPageContainer,
    "DocumentPage"
  );

  // <rh-react-ai-assistant-page />
  registerReactComponentWithAngularModule(
    angularModuleName,
    AiAssistantPage,
    "AiAssistantPage"
  );

  // <rh-react-taxonomy-tile-page />
  registerReactComponentWithAngularModule(
    angularModuleName,
    TaxonomyTilePageContainer,
    "TaxonomyTilePage"
  );

  // <rh-react-my-profile-page />
  registerReactComponentWithAngularModule(
    angularModuleName,
    MyProfilePage,
    "MyProfilePage"
  );

  // <rh-react-edit-profile-page />
  registerReactComponentWithAngularModule(
    angularModuleName,
    EditProfilePage,
    "EditProfilePage"
  );

  // <rh-react-asset-pdf-page />
  registerReactComponentWithAngularModule(
    angularModuleName,
    AssetPdfPage,
    "AssetPdfPage"
  );

  // <rh-react-login-error-page />
  registerReactComponentWithAngularModule(
    angularModuleName,
    LoginErrorPage,
    "LoginErrorPage"
  );

  // <rh-text-tile tile="..." />
  registerReactComponentWithAngularModule(
    angularModuleName,
    TextTile,
    "TextTile",
    ["tile"]
  );

  // <rh-period-search-filter-provider ... />
  registerReactComponentWithAngularModule(
    angularModuleName,
    PeriodSearchFilterProvider,
    "PeriodSearchFilterProvider",
    [
      "filter",
      "icon",
      "color",
      "registerCriteriaProvider",
      "criteria",
      "onCriteriaChanged",
      "titleResourceFrom",
      "titleResourceTo"
    ]
  );

  // <rh-react-search-preview ... />
  registerReactComponentWithAngularModule(
    angularModuleName,
    SearchPreview,
    "SearchPreview",
    []
  );

  // <rh-react-profile-email-required />
  registerReactComponentWithAngularModule(
    angularModuleName,
    ProfileEmailRequiredContainer,
    "ProfileEmailRequired"
  );

  // <rh-show-more-tool-tip children="..." />
  registerReactComponentWithAngularModule(
    angularModuleName,
    ShowMoreToolTip,
    "ShowMoreToolTip",
    ["text"],
    "lightweight"
  );

  // <rh-sla-measurement-mounter />
  registerReactComponentWithAngularModule(
    angularModuleName,
    SlaMeasurementMounter,
    "SlaMeasurementMounter",
    []
  );
}

// These are temporary exports that the old angularjs solution needs until more code is ported over.
// This list will expand, but then as entire features/pages have been moved it should decrease in size again.
export * from "./components/legacy-app-support/shellConfigurationTypes";
export * from "./components/search/criterias";
export * from "./components/search/search-request";
export * from "./components/search/SearchAssistant/build-criteria-from-terms.util";
export * from "./components/search/search-request-url-serializer";
export * from "./components/tiles/SearchTile/searchTile.util";
export * from "./components/ui-components/ThemeProvider/RhinestoneTheme";
export * from "./components/document/ExportDocument/export-document.util";
export * from "./components/document/content/pdf/LegacyPdfViewer/pdfjs-version-1_1_534-types";
export * from "./components/document/content/pdf/get-notes-from-merge-documents";
export * from "./components/user/features";
export * from "./components/document/content/shared/UrlTokens";
export * from "./components/document/content/shared/document-relation-groups/relation-group-search-utils";
export * from "./components/search/SearchRequestMapper";
export * from "./components/document/AssetCollection/asset-collection-utils";
export * from "./components/google-tag-manager-utils";
export * from "./components/account/profile-email-required/ProfileEmailRequired";
export * from "./components/sla-measurement/sla-measurement-api";
