import { usePortalApi } from "../api-access/use-portal-api";
import { useMemo } from "react";
import { UserViewModel } from "@rhinestone/portal-web-api";
import { Unauthorized } from "../api-access/http-status-codes";
import { QueryStatus } from "react-query";

export type UserContext = {
  isAuthenticated: boolean;
  user: UserViewModel | undefined;
  status: QueryStatus;
};

export function useUserContext(): UserContext {
  const { data, status } = usePortalApi(
    client => client.getLoggedInUser(),
    ["getLoggedInUser"],
    {
      expectedErrorStatusCodes: [Unauthorized]
    }
  );
  return useMemo(() => {
    return {
      isAuthenticated: status === "success" && Boolean(data),
      user: data,
      status
    };
  }, [data, status]);
}
