import React from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Box, Chip } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MimeTypeIcon from "../../ui-components/icons/MimetypeIcon";
import { LoadingProgress } from "../../ui-components/LoadingProgress";

interface AttachmentElementProps {
  isFetching: boolean;
  handleClick: () => void;
  title: string;
  fileName: string;
  mimeType: string;
}
const useStyles = makeStyles(theme => ({
  chip: {
    width: "90%",
    margin: theme.spacing(0.5),
    "& .MuiChip-label": {
      flexGrow: 10,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    },
    "& .MuiChip-deleteIcon": {
      color: theme.palette.primary.main
    },
    "& svg": {
      marginLeft: "4px"
    }
  },
  labelBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
}));
const AttachmentElement: React.FC<AttachmentElementProps> = ({
  title,
  mimeType,
  isFetching,
  handleClick
}) => {
  const classes = useStyles();

  return (
    <>
      <Chip
        className={classes.chip}
        icon={<MimeTypeIcon mimeType={mimeType} />}
        label={
          <Box className={classes.labelBox}>
            {title}
            {isFetching && <LoadingProgress size={24} />}
          </Box>
        }
        disabled={isFetching}
        onClick={handleClick}
        deleteIcon={<GetAppIcon />}
        onDelete={handleClick}
        variant="outlined"
        data-test-id="attachment-element"
      />
    </>
  );
};

export default AttachmentElement;
