import * as React from "react";
import { LocalizeProvider } from "react-localize-redux";
import { ThemeProvider } from "../ui-components/ThemeProvider";
import {
  LegacyAngularJsTranslationService,
  PortalConfigInfo,
  LegacyAngularJsScope
} from "./shellConfigurationTypes";
import TranslationContainer from "./translation/TranslationContainer";
import { LegacyGlobalCssOverrides } from "./LegacyGlobalCssOverrides";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { PortalApiProvider } from "../api-access/PortalApiProvider";
import { ReactQueryDevtools } from "react-query/devtools";
import ReactDOMServer from "react-dom/server";
import { SnackbarProvider } from "../ui-components/Snackbar";
import { LegacyLocationServiceSupport } from "./location";
import { UiRenderErrorBoundary } from "../error/UiRenderErrorBoundary";
import { SlaMeasurementContainer } from "../sla-measurement/SlaMeasurmentContainer";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
      retry: false
    }
  }
});

/**
 * This type models the angular services and providers we allow to be used by our temporary shell environment during transition
 */
export interface TemporaryShellProps {
  portal: PortalConfigInfo;
  $translate: LegacyAngularJsTranslationService;
  $scope: LegacyAngularJsScope;
}

export const withTemporaryShell = <T extends Record<string, unknown>>(
  Component: React.ComponentType<T>
): React.ComponentType<TemporaryShellProps & T> => {
  return ({ portal, $translate, $scope, ...rest }) => {
    const ComponentAsApp = () => <Component {...(rest as T)} />;
    return (
      <UiRenderErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider rhinestoneTheme={portal.rhinestoneTheme}>
            <LegacyGlobalCssOverrides />
            <BrowserRouter>
              {/* PortalApiProvider is also in charge of rendering general-API-call error-modals, so needs to be nested inside theme-provider. */}
              <PortalApiProvider portalIdentifier={portal.identifier}>
                {/* 
                Register functions for SLA measurement calls.

                Not that we also register Search functions from the AngularJs application during Run.
                */}
                <SlaMeasurementContainer />
                <LocalizeProvider
                  initialize={{
                    options: {
                      renderInnerHtml: true,
                      renderToStaticMarkup: ReactDOMServer.renderToStaticMarkup
                    },
                    languages: []
                  }}
                >
                  <TranslationContainer legacyTranslationService={$translate}>
                    <LegacyLocationServiceSupport angularScope={$scope}>
                      {/* SnackbarProvider needs to be inside Router provider for internal links in Snackbar to work*/}
                      <SnackbarProvider>
                        <Switch>
                          {/* Extend with all routes where we need access to params */}
                          <Route
                            path={[
                              "/h/:hiveId/:fullName*",
                              "/new-design/h/:hiveId/:fullName*",
                              "/a/h/:hiveId/:fullName*",
                              "/new-design/a/h/:hiveId/:fullName*"
                            ]}
                          >
                            <Component {...(rest as T)} />
                          </Route>
                          <Route
                            path={[
                              "/Samlinger/PdfView/:collectionId",
                              "/new-design/Samlinger/PdfView/:collectionId"
                            ]}
                          >
                            <Component {...(rest as T)} />
                          </Route>
                          <Route path={"/Account/MyProfile"}>
                            <Component {...(rest as T)} />
                          </Route>
                          <Route path={"/LoginError"}>
                            <Component {...(rest as T)} />
                          </Route>
                          <Route path={["/Ai-assistent/:configKey?"]}>
                            <Component {...(rest as T)} />
                          </Route>
                          {/* Default route for react-components that doesn't belong to a specific URL */}
                          <Route>{ComponentAsApp}</Route>
                        </Switch>
                      </SnackbarProvider>
                    </LegacyLocationServiceSupport>
                  </TranslationContainer>
                </LocalizeProvider>
              </PortalApiProvider>
            </BrowserRouter>
          </ThemeProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </UiRenderErrorBoundary>
    );
  };
};
