import * as React from "react";
import {
  SlaMeasurement,
  registerSlaMeasurementFunction
} from "./sla-measurement-api";
import { useContext, useEffect } from "react";
import { PortalApiContext } from "../api-access/PortalApiProvider";

export const SlaMeasurementContainer: React.FC = () => {
  const portalApiContext = useContext(PortalApiContext);

  // We only want to initialize this component on startup
  useEffect(() => {
    const client = portalApiContext[0];
    registerSlaMeasurementFunction(
      "getDocument",
      async (
        slaRegistration: SlaMeasurement,
        hiveId: string,
        fullName: string
      ) => {
        const result =
          await client.getPrimaryVariantContentExcludeEditorialNotes(
            hiveId,
            fullName,
            undefined,
            // TODO: Can we set these headers without passing them to the client?
            slaRegistration
          );

        return result._response;
      }
    );

    registerSlaMeasurementFunction(
      "wildCardSearch",
      async (slaRegistration: SlaMeasurement) => {
        const result = await client.search(
          {
            criteria: {
              terms: ["*"]
            },
            fieldSetName: "SearchResultFields",
            take: 10,
            snippets: true
          },
          {
            "x-sla-measurement-tool": slaRegistration.slaTool,
            "x-sla-measurement-transaction": slaRegistration.slaTransaction
          }
        );

        return result._response;
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // We don't render anything to the UI from the sla measurement component
  return null;
};
