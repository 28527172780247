import { UserProfileDto, UserViewModel } from "@rhinestone/portal-web-api";

const dataLayer = () => (window as any).dataLayer || [];

export function SendDocumentOpenedGTMEvent(
  documentTitle: string,
  documentTypeTaxonKey: string,
  documentTypeTaxonTitle: string,
  organizationName: string | undefined
) {
  dataLayer().push({
    event: "documentOpened",
    documentTitle,
    // We are sending the document key twice for backwards compatibility
    // - from before both the key and title were send
    documentType: documentTypeTaxonKey,
    documentTypeTaxonKey,
    documentTypeTaxonTitle,
    organizationName: organizationName
  });
}

export function SendTermsSearchGTMEvent(
  searchTerm: string,
  organizationName: string
) {
  dataLayer().push({
    event: "termSearchTriggered",
    searchTerm,
    organizationName: organizationName
  });
}

export function SendPageViewGtmEvent(
  location: string,
  title: string,
  user: UserViewModel,
  userProfile: UserProfileDto
) {
  dataLayer().push({
    event: "ngRouteChange",
    attributes: {
      route: location,
      title: title,
      isAuthenticated: !!user,
      organizationName: user.organizationName,
      organizationIdentifier: user?.organizationIdentifier,
      email: userProfile.emailAddresses[0],
      displayName: user.displayName,
      userId: user.id
    }
  });
}

export function SendAnonymousPageViewGtmEvent(location: string, title: string) {
  dataLayer().push({
    event: "ngRouteChange",
    attributes: {
      route: location,
      title: title
    }
  });
}
