export function registerSlaMeasurementFunction<T>(
  functionName: string,
  functionBody: (slaRegistration: SlaMeasurement, ...args: any[]) => Promise<T>
) {
  // If we run under conditions with no window object, we won't expose the sla measurement API.
  // It is intended to be used from a UI test environment (Namely OneView).
  if (window === undefined) return;

  // Create the measurementApi object if it doesn't exist
  (window as any).measurementApi = (window as any).measurementApi ?? {};

  // Bind the function to the measurementApi object
  (window as any).measurementApi[functionName] = functionBody;
}

export interface SlaMeasurement {
  slaTool: string;
  slaTransaction: string;
}
